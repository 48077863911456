import _ from 'lodash'
import moment from 'moment'

export const JOB_STATUS = [
  '入稿',
  'テンプレート設定',
  'エントリー１',
  'エントリー２',
  'ベリファイ',
  '納品',
  '確認中',
  'キャンセル',
]

export const PROCESSED_JOB_STATUS = ['納品', 'キャンセル']

export const PLAN_OPTIONS = [
  { key: 'trial', value: 'おためしANNIM', text: 'おためしANNIM' },
  { key: 'entry', value: 'エントリー', text: 'エントリー' },
  { key: 'light', value: 'ライト', text: 'ライト' },
  { key: 'standard', value: 'スタンダード', text: 'スタンダード' },
]

export const ADMIN_PLAN_OPTION = _.concat(PLAN_OPTIONS, {
  key: 'cancelled',
  value: '廃止',
  text: '廃止',
})

const ORIGIN_YEAR = 2019
const YEAR_RANGE = _.range(ORIGIN_YEAR, moment().year() + 1)
export const YEAR_OPTIONS = _.map(YEAR_RANGE, (y) => ({
  key: y,
  value: y,
  text: `${y} 年`,
}))

export const JOB_TABLE_HEADER = [
  '案件番号',
  '入稿者',
  '入稿者所属',
  'テンプレート名',
  '納品枚数',
  '入稿日時',
  '納品日時',
]

export const TAMPLATE_TABLE_HEADER = [
  'テンプレート名',
  '登録日時',
  '月間無料枠内',
]
