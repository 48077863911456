import _ from 'lodash'
import firebase from './firebase'
import { useEffect, useState, useReducer, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { FirebaseContext } from './context'
import { querySnapToObj } from '../lib/misc'
import { JOB_STATUS } from '../lib/constants'

const PROCESSING_STATUS = _.without(JOB_STATUS, '納品', 'キャンセル')

const FIREBASE_DEFAULTS = {
  firebase,
  auth: firebase.auth(),
  db: firebase.firestore(),
  functions: firebase.app().functions('asia-northeast1'),
  storage: firebase.storage(),
}

export const useFirebase = () => {
  const [claims, setClaims] = useState(null)
  const [configPublic, setConfigPublic] = useState(null)
  const [initStatus, setInitStatus] = useReducer(
    (state, data) => _.assign({}, state, data),
    { config: true, user: true }
  )

  const auth = firebase.auth()

  useEffect(() => {
    if (!configPublic) return
  }, [configPublic])

  useEffect(() => {
    const authUnsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log(`sign in as ${user.uid}`)
        const tokenResult = await user.getIdTokenResult(true)
        setClaims(tokenResult.claims)
      } else {
        console.log('signed out')
        setClaims(null)
      }

      setInitStatus({ user: false })
    })

    const configPublicUnsub = FIREBASE_DEFAULTS.db
      .doc('config/public')
      .onSnapshot((doc) => {
        setConfigPublic(doc.data())
        setInitStatus({ config: false })
      })

    return () => {
      _.forEach([authUnsub, configPublicUnsub], (u) => u())
    }
  }, [auth])

  const updateClaims = async () => {
    const tokenResult = await auth.currentUser.getIdTokenResult(true)
    setClaims(tokenResult.claims)
  }

  return {
    ...FIREBASE_DEFAULTS,
    initializing: _.some(_.values(initStatus)),
    currentUser: auth.currentUser,
    claims,
    updateClaims,
    configPublic,
  }
}

export const useCustomer = () => {
  const { organizationId, departmentId } = useParams()
  const { claims, db, currentUser, firebase } = useContext(FirebaseContext)
  const [initStatus, setInitStatus] = useReducer(
    (state, data) => _.assign({}, state, data),
    { organizations: true, jobs: true, departments: true, forests: true }
  )
  const [organizations, setOrganizations] = useState(null)
  const [jobs, setJobs] = useState(null)
  const [departments, setDepartments] = useState(null)
  const [forests, setForests] = useState(null)
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    departmentId &&
      _.includes(
        claims.organizations[organizationId].departmentIds,
        departmentId
      )
      ? departmentId
      : null
  )

  useEffect(() => {
    // initialize
    setInitStatus({
      organizations: true,
      jobs: true,
      departments: true,
      forests: true,
    })

    if (!currentUser) {
      setInitStatus({
        organizations: false,
        jobs: false,
        departments: false,
        forests: false,
      })
      setOrganizations(null)
      setJobs(null)
      setDepartments(null)
      setForests(null)
      return
    }

    if (!organizationId) {
      setSelectedDepartmentId(null)
      setJobs(null)
    }

    if (departmentId && !claims.organizations[organizationId].isManager)
      setSelectedDepartmentId(departmentId)

    const organizationIds = _.keys(_.get(claims, 'organizations', {}))

    const organizationsUnsub = (() => {
      if (_.isEmpty(organizationIds)) {
        setInitStatus({ organizations: false })
        return
      }
      return db
        .collection('organizations')
        .where(firebase.firestore.FieldPath.documentId(), 'in', organizationIds)
        .onSnapshot((snap) => {
          setOrganizations(querySnapToObj(snap))
          setInitStatus({ organizations: false })
        })
    })()

    const jobsUnsub = (() => {
      if (organizationId) {
        let query = db
          .collectionGroup('jobs')
          .where('organizationId', '==', organizationId)
          .orderBy('createdAt', 'desc')
        if (selectedDepartmentId)
          query = query.where('departmentId', '==', selectedDepartmentId)
        return query.onSnapshot((snap) => {
          setJobs(querySnapToObj(snap))
          setInitStatus({ jobs: false })
        })
      }
      setInitStatus({ jobs: false })
    })()

    const departmentsUnsub = (() => {
      if (_.isEmpty(organizationIds)) {
        setInitStatus({ departments: false })
        return
      }
      let query = db
        .collectionGroup('departments')
        .where('organizationId', 'in', organizationIds)
      return query.onSnapshot((snap) => {
        setDepartments(querySnapToObj(snap))
        setInitStatus({ departments: false })
      })
    })()

    const forestsUnsub = db
      .collection('forests')
      .where('userIds', 'array-contains', currentUser.uid)
      .onSnapshot((snap) => {
        setForests(querySnapToObj(snap))
        setInitStatus({ forests: false })
      })

    return () => {
      const detachments = [forestsUnsub]
      if (organizationId) detachments.push(jobsUnsub)
      if (!_.isEmpty(organizationIds)) {
        detachments.push(organizationsUnsub)
        detachments.push(departmentsUnsub)
      }
      _.forEach(detachments, (u) => u())
    }
  }, [
    organizationId,
    departmentId,
    selectedDepartmentId,
    db,
    currentUser,
    claims,
    firebase.firestore.FieldPath,
  ])

  return {
    initializing: _.some(_.values(initStatus)),
    organizations,
    jobs,
    departments,
    forests,
    selectedDepartmentId,
    setSelectedDepartmentId,
  }
}

export const useStaff = () => {
  const { claims, db, currentUser } = useContext(FirebaseContext)
  const [initStatus, setInitStatus] = useReducer(
    (state, data) => _.assign({}, state, data),
    { orgs: true, processingJobs: true, users: true, departments: true }
  )

  const [organizations, setOrganizations] = useState(null)
  const [processingJobs, setProcessingJobs] = useState(null)
  const [users, setUsers] = useState(null)
  const [departments, setDepartments] = useState(null)

  useEffect(() => {
    // initialize
    setInitStatus({
      orgs: true,
      processingJobs: true,
      users: true,
      departments: true,
    })

    if (!currentUser) {
      setInitStatus({
        orgs: false,
        processingJobs: false,
        users: false,
        departments: false,
      })
      return
    }

    const orgsUnsub = db.collection('organizations').onSnapshot((snap) => {
      setOrganizations(querySnapToObj(snap))
      setInitStatus({ orgs: false })
    })

    const processingJobsUnsub = (() => {
      let query = db
        .collectionGroup('jobs')
        .where('status', 'in', PROCESSING_STATUS)
      if (claims.role !== 'admin')
        query = query.where(
          'relatedOperatorIds',
          'array-contains',
          currentUser.uid
        )
      return query.orderBy('createdAt', 'desc').onSnapshot((snap) => {
        setProcessingJobs(querySnapToObj(snap))
        setInitStatus({ processingJobs: false })
      })
    })()

    const usersUnsub = db.collection('users').onSnapshot((snap) => {
      const staffs = _.transform(
        snap.docs,
        (result, doc) => {
          if (doc.data().role !== 'customer')
            result[doc.id] = _.assign(doc.data(), { id: doc.id })
        },
        {}
      )
      const customers = _.transform(
        snap.docs,
        (result, doc) => {
          if (doc.data().role === 'customer')
            result[doc.id] = _.assign(doc.data(), { id: doc.id })
        },
        {}
      )
      setUsers({ staffs, customers })
      setInitStatus({ users: false })
    })

    const departmentsUnsub = db
      .collectionGroup('departments')
      .onSnapshot((snap) => {
        setDepartments(querySnapToObj(snap))
        setInitStatus({ departments: false })
      })

    return () =>
      _.forEach(
        [orgsUnsub, processingJobsUnsub, usersUnsub, departmentsUnsub],
        (u) => u()
      )
  }, [db, currentUser, claims.role])

  return {
    initializing: _.some(_.values(initStatus)),
    organizations,
    processingJobs,
    users,
    departments,
  }
}
