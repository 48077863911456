import _ from 'lodash'
// import Analytics from 'react-router-ga'

import React, { useContext, Suspense, lazy } from 'react'
import { BrowserRouter, Switch, Route, Redirect, Link } from 'react-router-dom'
import { Container, Image } from 'semantic-ui-react'

import { FirebaseContext } from './lib/context'
import { useFirebase } from './lib/hook'

import LoadingScreen from './LoadingScreen'
import MaintenanceScreen from './MaintenanceScreen'

const AdminRoute = lazy(() => import('./components/admin/AdminRoute'))
const AdminScreen = lazy(() => import('./components/admin/AdminScreen'))
const OperatorRoute = lazy(() => import('./components/operator/OperatorRoute'))
const OperatorScreen = lazy(() =>
  import('./components/operator/OperatorScreen')
)
const CustomerRoute = lazy(() => import('./components/customer/CustomerRoute'))
const CustomerScreen = lazy(() =>
  import('./components/customer/CustomerScreen')
)
const HomeScreen = lazy(() => import('./HomeScreen'))
const NotFoundScreen = lazy(() => import('./NotFoundScreen'))
const CustomerSignup = lazy(() =>
  import('./components/customer/CustomerSignup')
)
const PasswordReset = lazy(() => import('./PasswordReset'))

const FirebaseInitWrapper = ({ children }) => {
  const { initializing } = useContext(FirebaseContext)
  if (initializing) return <LoadingScreen />
  return children
}

const MaintenanceMode = ({ children }) => {
  const { configPublic } = useContext(FirebaseContext)
  if (configPublic.isMaintenanceMode) return <MaintenanceScreen />
  return children
}

const SignOut = () => {
  const { auth } = useContext(FirebaseContext)
  auth.signOut()

  return <Redirect to='/' />
}

const Home = () => {
  const { claims } = useContext(FirebaseContext)
  const url = {
    admin: '/admin/jobs',
    operator: '/operator/jobs',
    customer: '/customer',
    null: '/customer',
  }[_.get(claims, 'role', null)]
  return <Redirect to={url} />
}

const Logo = () => (
  <Link to='/'>
    <Image
      src='/assets/annim-logo.png'
      size='small'
      style={{
        marginBottom: 10,
      }}
    />
  </Link>
)

const Routes = () => (
  <BrowserRouter>
    {/* <Analytics id={process.env.REACT_APP_GA_TRACKING_CODE}> */}
    <Suspense fallback={<LoadingScreen />}>
      <Container style={{ paddingTop: 15 }}>
        <Logo />
        <Switch>
          <Route exact path='/' component={Home} />
          <AdminRoute path='/admin' component={AdminScreen} />
          <OperatorRoute path='/operator' component={OperatorScreen} />
          <CustomerRoute path='/customer' component={CustomerScreen} />
          <Route path='/signup' component={CustomerSignup} />
          <Route path='/signout' component={SignOut} />
          <Route path='/password-reset' component={PasswordReset} />
          <Route path='/op/signin' component={HomeScreen} />
          <Route
            path='/customers'
            component={() => <Redirect to='/customer' />}
          />
          <Route path='*' component={NotFoundScreen} />
        </Switch>
      </Container>
    </Suspense>
    {/* </Analytics> */}
  </BrowserRouter>
)

export default () => (
  <FirebaseContext.Provider value={useFirebase()}>
    <FirebaseInitWrapper>
      <MaintenanceMode>
        <Routes />
      </MaintenanceMode>
    </FirebaseInitWrapper>
  </FirebaseContext.Provider>
)
