import _ from 'lodash'
import papa from 'papaparse'
import moment from 'moment'
import jsPDF from 'jspdf'

import { JOB_TABLE_HEADER, TAMPLATE_TABLE_HEADER } from './constants'

import './Koruri-Regular-normal'
import 'jspdf-autotable'

export const querySnapToObj = (snap) =>
  _.transform(
    snap.docs,
    (result, doc) => {
      result[doc.id] = _.assign(doc.data(), { id: doc.id })
    },
    {}
  )

export const authErrorMessage = (error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      return 'メールアドレスが既に使用されています。'
    case 'auth/invalid-email':
      return '無効なメールアドレスです。'
    case 'auth/weak-password':
      return 'より強度なパスワードを設定してください。'
    case 'auth/user-disabled':
      return 'そのユーザは凍結されています。'
    case 'auth/user-not-found':
      return 'そのメールアドレスは未登録です。'
    case 'auth/wrong-password':
      return 'メールアドレスかパスワードが間違っています。'
    default:
      return error.code
  }
}

// 受け取ったdateを案件番号に変換
export const convertToJobNumber = (date) =>
  moment(date.toDate()).format('YYYYMMDD-HHmmss-SSS')

export const downloadCSV = (fields, data, fileName) => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  const csvData = papa.unparse({ fields, data })

  const blob = new Blob([bom, csvData], { type: 'text/plain' })
  if (window.navigator.msSaveBlob) {
    // IE,Edge
    window.navigator.msSaveBlob(blob, fileName)
  } else {
    // IE,Edge以外
    const objectURL = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.href = objectURL
    link.download = fileName
    link.click()
    document.body.removeChild(link)
  }
}

export const tableRowFilterAndSort = (props) => {
  const {
    data,
    searchTargetFields,
    searchFilter,
    sortColumn,
    sortDirection,
  } = props

  return _(data)
    .filter((v) => {
      if (_.isEmpty(searchFilter)) return true

      const targets = _.map(searchTargetFields, (target) => v[target])

      for (const v of targets) {
        if (_.includes(v, searchFilter)) return true
      }
      return false
    })
    .sortBy(sortColumn)
    .thru((v) => (sortDirection === 'descending' ? _.reverse(v) : v))
    .value()
}

export const calcJobSumGroupByOrg = (jobStatsGroupByDepartment) => {
  if (!jobStatsGroupByDepartment) return 0

  return _(jobStatsGroupByDepartment)
    .map((jobStatsGroupByUser) => _.map(jobStatsGroupByUser, (v) => v.jobSum))
    .flatten()
    .sum()
}

export const calcProcessedPageSumGroupByOrg = (jobStatsGroupByDepartment) => {
  if (!jobStatsGroupByDepartment) return 0

  return _(jobStatsGroupByDepartment)
    .map((jobStatsGroupByUser) =>
      _.map(jobStatsGroupByUser, (v) => v.processedPageSum)
    )
    .flatten()
    .sum()
}

export const downloadDeliveryNote = ({
  jobTableBody,
  templateTableBody,
  organizationName,
  deliveryPeriod,
  deliveryNumber,
  fileName,
}) => {
  const doc = new jsPDF({ size: 'a4' })
  doc.setFont('Koruri-Regular', 'normal')
  doc.setFontSize(25)
  doc.text(55, 30, '【ANNIM】納品明細書')

  doc.setFontSize(18)
  doc.text(10, 45, 'お客様名：')

  const organizationNameFontSize = (() => {
    if (organizationName.length <= 10) return 15
    if (organizationName.length <= 20) return 13
    if (organizationName.length <= 30) return 11
    if (organizationName.length <= 40) return 9
    return 6
  })()

  doc.setFontSize(organizationNameFontSize)
  doc.text(45, 45, organizationName)
  doc.setFontSize(18)
  doc.text(180, 45, ' 御中')

  doc.setFontSize(12)
  doc.text(10, 55, '今月はANNIMをご利用いただきましてありがとうございました。')
  doc.text(
    10,
    60,
    '以下の通り納品させていただきましたので、ご確認いただきますようお願いいたします。'
  )

  doc.text(15, 70, `納品期間：  ${deliveryPeriod}`)
  doc.text(15, 80, `納品枚数：  ${deliveryNumber} 枚`)

  doc.text(10, 90, '■納品実績')

  doc.autoTable({
    startY: 100,
    styles: { font: 'Koruri-Regular', fontStyle: 'normal', fontSize: 8 },
    head: [JOB_TABLE_HEADER],
    body: jobTableBody,
  })

  // 納品ジョブテーブルの行数によってテンプレートテーブルの高さを調整
  // 納品ジョブテーブル1行につき + 7
  const templateTableStartY = 130 + jobTableBody.length * 7

  doc.text(10, templateTableStartY, '■テンプレート登録実績')
  doc.text(
    10,
    templateTableStartY + 5,
    '「月間無料枠内」にチェックが入っているテンプレートは無料です'
  )

  doc.autoTable({
    startY: templateTableStartY + 15,
    styles: { font: 'Koruri-Regular', fontStyle: 'normal', fontSize: 8 },
    head: [TAMPLATE_TABLE_HEADER],
    body: templateTableBody,
  })

  doc.save(fileName)
}
