import React from 'react'
import { Container } from 'semantic-ui-react'
import { Image, Message } from 'semantic-ui-react'

export default () => {
  return (
    <Container style={{ marginTop: 120, marginBottom: 10 }}>
      <Image
        src='/assets/annim-logo.png'
        inline
        size='small'
        style={{ marginRight: 25 }}
      />
      <Message compact>
        現在、システムのメンテナンス中です。
        <br />
        お手数ですが、しばらく経ってから、画面をリロードしてください。
      </Message>
    </Container>
  )
}
